<template>
  <div>
    <div class="mb-14 mt-6">
      <h2 class="text-center text-2xl tracking-tighter leading-9 font-extrabold mb-2r">
        Sign in to your business
      </h2>
      <p class="text-sm mt-2 opacity-75 text-center leading-5">
        Enter your businesses Ovatu domain name
      </p>
    </div>

    <div class="mt-8">
      <div class="field-group">
        <div class="field-group">
          <div class="field w-full flex items-center h-12 border border-gray-200 dark:border-gray-800 rounded-lg bg-white dark:bg-gray-900 overflow-hidden">
            <input
              v-model="domain"
              placeholder="your-domain"
              required
              class="text-base flex-grow appearance-none w-full px-3 py-3 placeholder-gray-500 dark:placeholder-white/50 text-gray-900 dark:text-white h-full dark:bg-gray-900 focus:outline-none focus:bg-blue-100 dark:focus:bg-gray-800 focus:shadow-outline-blue focus:border-blue-300 focus:z-10 sm:leading-5"
              @keydown.enter="handleKeyDown"
            >
            <div class="text-base flex items-center px-4 h-full bg-gray-50 dark:bg-gray-900 font-medium opacity-75 border-l">
              .ovatu.app
            </div>
          </div>
        </div>
      </div>

      <div class="mt-8">
        <button class="group relative w-full flex justify-center py-3 px-4 border border-transparent text-sm font-medium rounded-xl text-white bg-primary-500 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500" @click="handleSubmitDomain">
          Continue
        </button>
      </div>

      <div class="mt-12 text-sm text-center">
        <div>
          <p class="opacity-75">
            Don't know your Ovatu domain? <nuxt-link to="/auth/find/email" class="text-blue-500 font-medium">Find your account</nuxt-link>
          </p>
        </div>


      <div class="text-sm mt-3 opacity-75">
        <p class="font-medium">This login is for Ovatu Next</p>
        <p>If you use Ovatu Classic, please <a href="https://ovatu.com/location" class="underline">login here</a>.</p>
        <p>If you are ready to move over to Ovatu Next, please contact <a href="mailto:hello@ovatu.com" class="underline">hello@ovatu.com</a></p>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  OAlert
} from '@/components/elements'

import * as API from '@/api'

export default {
  components: {
    OAlert
  },
  data () {
    return {
      domain: ''
    }
  },
  methods: {
    async handleSubmitDomain () {
      try {
        const domain = this.domain
        const response = await API.Auth.domain(domain)

        if (response) {
          const location = `https://${response.slug}.${this.$auth.url}`
          window.location = location
        }
      } catch (e) {
        console.log(e)
        this.$notification.error({ errors: e?.errors })
      }
    },
    handleKeyDown () {
      if (this.domain) {
        this.handleSubmitDomain()
      }
    }
  }
}
</script>
